const $ = require('jquery');
global.$ = global.jQuery = $;

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app-home.scss';

import 'bootstrap';
import "slick-carousel/slick/slick.min";

$(document).ready(function(){

    let containerSlickSelector = $('.container-slides');

    containerSlickSelector.on('init', function(event, slick){
        var dots = $( '.slick-dots li' );
        dots.each( function( k, v){
            $(this).find( 'button' ).addClass( 'heading'+ k );
        });
        var items = slick.$slides;
        items.each( function( k, v){
            var text = $(this).attr('data-title');
            $( '.heading' + k ).text(text);
        });
    });

    containerSlickSelector.slick({
        autoplay: true,
        dots: true,
        arrows: false,
        fade: true,
        pauseOnHover: false,
        autoplaySpeed: 5000,
        infinite: true,
    });
});
